/* eslint-disable no-undef */
'use strict';

module.exports = {
    init: function () {
        // Add more items handler
        $('body').on('click', '#addMoreItems', function (e) {
            e.preventDefault();
            var $button = $('#addMoreItems');
            var count = $button.data('count');
            var url = $button.data('url');
            $.spinner().start();
            var params = $.param({
                isAjax: true,
                count: count,
                items: JSON.stringify($('#itemsTable').data('items'))
            });
            $.ajax({
                url: url,
                type: 'get',
                data: params,
                success: function (data) {
                    $.spinner().stop();
                    if (!data || !data.success) {
                        if (data.message) {
                            window.console.error(data.message);
                        }
                        return;
                    }
                    $('#itemsTablePlaceholder').html(data.itemsTableHtml);
                    return;
                },
                error: function (data) {
                    $.spinner().stop();
                }
            });
        });
        // Update fields
        $('body').on('change', '#itemsTablePlaceholder input', function (e) {
            var items = [];
            $('#itemsTablePlaceholder input').each(function (i, input) {
                var $input = $(input);
                var itemNum = parseInt($input.data('num'), 10);
                var fieldName = $input.data('name');
                var fieldValue;
                switch ($input.attr('type')) {
                    case 'checkbox':
                        fieldValue = $input.is(':checked');
                        break;
                    default:
                        fieldValue = $input.val();
                        break;
                }
                if (items.length < itemNum) {
                    items.push({});
                }
                items[itemNum - 1][fieldName] = fieldValue;
            });
            $('#itemsTable').data('items', items);
        });
        // Generate txn_id
        $('body').on('click', '#generateTxnId', function (e) {
            e.preventDefault();
            var $button = $('#generateTxnId');
            var url = $button.data('url');
            $.spinner().start();
            var params = $.param({
                isAjax: true
            });
            $.ajax({
                url: url,
                type: 'get',
                data: params,
                success: function (data) {
                    $.spinner().stop();
                    if (!data || !data.success) {
                        if (data.message) {
                            window.console.error(data.message);
                        }
                        return;
                    }
                    $('#txn_id').val(data.txnId);
                    return;
                },
                error: function (data) {
                    $.spinner().stop();
                }
            });
        });
        // Fill in test data (MX)
        $('body').on('click', '#cta-fill-in-data-mx', function (e) {
            e.preventDefault();
            $('[name="shipto_firstName"]').val('John');
            $('[name="shipto_lastName"]').val('Doe');
            $('[name="shipto_name"]').val('John Doe');
            $('[name="shipto_streetAddress"]').val('AN LUIS DE LA PAZ# 82');
            $('[name="shipto_addressLine2"]').val('Unit 1');
            $('[name="shipto_city"]').val('CHIHUAHUA');
            $('[name="shipto_state"]').val('CHIHUAHUA');
            $('[name="shipto_zip"]').val('31000');
            $('[name="shipto_phone"]').val('4422196644');
            $('[name="shipto_county"]').val('');

            $('[name="item_sku"]').val('10074680');

            $('[name="customer_key_client"]').val('103134');
            $('[name="client_email"]').val('john.doe@gmail.com');
            $('[name="client_phone"]').val('4422196633');
            $('[name="client_firstName"]').val('Ana');
            $('[name="client_lastName"]').val('Morena');
            $('[name="client_middleName"]').val('Belen');
            $('[name="customer_language"]').val('es_MX');
            $('[name="customer_key_ibc"]').val('103134');
        }); 
        // Fill in test data (ES)
        $('body').on('click', '#cta-fill-in-data-es', function (e) {
            e.preventDefault();
            $('[name="shipto_firstName"]').val('Jone');
            $('[name="shipto_lastName"]').val('Davila');
            $('[name="shipto_name"]').val('Davila Jone');
            $('[name="shipto_streetAddress"]').val('C. de Padilla, 5, Salamanca');
            $('[name="shipto_addressLine2"]').val('3 o, 17');
            $('[name="shipto_city"]').val('MADRID');
            $('[name="shipto_state"]').val('MADRID');
            $('[name="shipto_zip"]').val('28007');
            $('[name="shipto_phone"]').val('341231231234');
            $('[name="shipto_county"]').val('');

            $('[name="item_sku"]').val('10144266');

            $('[name="customer_key_client"]').val('103134');
            $('[name="client_email"]').val('john.doe@gmail.com');
            $('[name="client_phone"]').val('+34976109092');
            $('[name="client_firstName"]').val('Jone');
            $('[name="client_lastName"]').val('Davila');
            $('[name="client_middleName"]').val('');
            $('[name="customer_language"]').val('es_ES');
            $('[name="customer_key_ibc"]').val('103134');
        });
        // Generate Get request
        $('body').on('click', '#generateGet', function (e) {
            e.preventDefault();
            var $button = $('#generateGet');
            var url = $button.data('url');
            $.spinner().start();
            var $form = $('#form');
            var fdata = $form.serialize();
            $.ajax({
                url: url,
                type: 'get',
                data: fdata,
                success: function (data) {
                    $.spinner().stop();
                    if (!data || !data.success) {
                        if (data.message) {
                            window.console.error(data.message);
                        }
                        return;
                    }
                    $('#getRequestField').val(data.getRequestStr);
                    return;
                },
                error: function (data) {
                    $.spinner().stop();
                }
            });
        });
    }
};
